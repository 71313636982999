<!-- 房产开发 -->
<template>
  <div>
    <div class="container">
      <div class="row mt-80-mb-140">
        <div
          class="item col-lg-4 col-md-4 col-sm-6"
          v-for="item in landList"
          :key="item"
          @click.capture="goToDetail(item.bid)"
        >
          <div
            class="box featured-imagebox featured-imagebox-portfolio position-relative"
          >
            <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
              <!-- featured-thumbnail -->
              <div class="featured-thumbnail">
                <img :src="item.image" class="" />
              </div>
              <!-- featured-thumbnail end-->
              <div class="ttm-media-link">
                <i class="bi bi-plus"></i>
              </div>
            </div>
            <div
              class="featured-content d-flex justify-content-center text-center"
            >
              <h3>{{ item.title }}</h3>
              <span>{{ item.subtitle }}</span>
            </div>
          </div>
        </div>
        <PaginationBox
          :total="pageObj.total_count"
          v-model:currentPage="currentPage"
        ></PaginationBox>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { ref, onMounted, watch } from 'vue'
import { getLand } from '../../../server/business'
import PaginationBox from '@/components/PaginationBox'

const router = useRouter()
const landList = ref([])
const pageObj = ref({})
const currentPage = ref(1)
onMounted(async () => {
  const r = await getLand(currentPage.value)
  landList.value = r.list
  pageObj.value = r.cur_page
})
const goToDetail = (bid) => {
  router.push({
    path: '/BusinessSection/LandDetail',
    query: { bid: bid }
  })
}
watch(currentPage, async () => {
  const r = await getLand(currentPage.value)
  landList.value = r.list
  pageObj.value = r.cur_page
})
</script>
<style lang="stylus" scoped>
.box
  width 325px
  height 325px
  margin 0 auto
  position relative
  margin-bottom 120px
  .ttm-box-view-overlay
    width 100%
    height 100%
    background rgba(195, 195, 195, 1)
  .ttm-media-link
    position absolute
    right 0px
    top 0
    color: var(--white-color);
    text-align: center;
    font-size: 28px;
    line-height: 50px;
    position: absolute;
    height: 50px;
    width: 50px;
    text-align: center;
    z-index: 2;
    transform: translateX(50px);
    opacity: 0;
    transition: all .4s;
    transition-delay: .1s;
    background-color: #000;
  .featured-thumbnail
    // height 370px
    width 100%
    height 100%
    overflow hidden
    position: relative;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    &::after
      content: "";
      width: 100%;
      height: 0%;
      background-color: rgba(0, 0, 0, 0.40);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      -webkit-transition: all .5s ease-in-out 0s;
      -moz-transition: all .5s ease-in-out 0s;
      -ms-transition: all .5s ease-in-out 0s;
      -o-transition: all .5s ease-in-out 0s;
      transition: all .5s ease-in-out 0s;
    img
      object-fit contain
      width 100%
      height 100%
      transition: transform 0.5s ease-in-out;
  .featured-content
    position absolute
    bottom -60px
    background #fff
    flex-wrap wrap
    left 0
    right 20px
    height 114px
    flex-direction column
    font-size: 24px;
    font-family: Microsoft YaHei;
    &::before
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background: #FFA800;
      z-index: -1;
      transition: .5s;
    h3
      width 100%
      text-align center
      font-weight: bold;
      color: #10366B;
      z-index 2
    span
      font-size: 16px;
      color: #10366B;
      z-index 2

.featured-imagebox-portfolio:hover
  cursor pointer
  .featured-thumbnail
    &::after
      height: 100%;
      opacity: 1;
      overflow: hidden;
    img
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
  .ttm-media-link
    opacity: 1;
    transform: translateX(0);
    background: #FFA800;
    color #fff
.featured-imagebox-portfolio:hover
  .featured-content
    color #fff
    &::before
      z-index 1
      height 100%
    h3
      color #fff
    span
      color #fff
.pagination
  a
    cursor pointer
</style>
